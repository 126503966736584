<script setup>
import api from '@/api-client'
import { ref, onMounted } from 'vue'
import { DateTime } from 'luxon'
import EventCard from '@/components/EventCard.vue'

const props = defineProps({
    date: {
        type: String,
        required: true
    }
})

const events = ref(null)

const fetchData = async () => {
    const { data } = await api.get('blocks/calendar/v1', {
        params: {
            from: DateTime
                .fromISO(props.date, { zone: $cookies.get('timezone') })
                .startOf('day')
                .toUTC()
                .toISO(),
            to: DateTime
                .fromISO(props.date, { zone: $cookies.get('timezone') })
                .endOf('day')
                .toUTC()
                .toISO(),
            incidents: false,
            maintenances: true
        }
    })
    console.log(data.data)

    if (!data.data.length) {
        events.value = []
        return
    }

    const promises = data.data
        .map(async ({ id }) => await api.get(`maintenances/${id}`))
    console.log(promises)
    Promise.all(promises)
        .then(values => events.value = values
            .map(({ data }) => ({ ...data, event_type: 1 }))
        )
}
onMounted(fetchData)
</script>

<template>
    <div class="maintenances-calendar-list">
        <template v-if="events">
            <ul v-if="events.length">
                <EventCard
                    v-for="event in events"
                    :key="event.id"
                    :event="event"
                    :count="events.length"
                />
            </ul>
            <p v-else>{{ $t('summary.planned-maintenance.no-events') }}</p>
        </template>
    </div>
</template>

<style scoped lang="scss">
.maintenances-calendar-list {
    overflow-y: auto;
    scrollbar-color: #BFBFBF transparent;

    ul {
        margin: 0;
    }

    p {
        text-align: center;
    }
}
</style>
